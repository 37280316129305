.flow-form-main .SNOWS-el_tabs[data-v-604664ac] {
  overflow: hidden;
}
.color-box[data-v-604664ac] {
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 50%;
}
.flow-urgent-value[data-v-604664ac] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.flow-urgent-value span[data-v-604664ac]:first-child {
  margin: 0 3px 0 10px;
}
.options .dropdown[data-v-604664ac] {
  margin-right: 10px;
}
.options .el-button[data-v-604664ac] {
  min-width: 70px;
}
.dropdown-item[data-v-604664ac] {
  min-width: 70px;
  text-align: center;
}