.recordSummary .el-tab-pane[data-v-70722642] {
  height: 0;
}
.recordSummary[data-v-70722642] .el-tabs__content {
  height: 100%;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list {
  height: 100%;
  overflow: hidden auto;
  padding: 50px 100px 0;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item {
  margin-bottom: 20px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body {
  padding: 0;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .cap {
  width: 150px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  text-align: center;
  font-size: 14px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .content {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0px 36px;
  border-left: 1px solid #e4e7ed;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item {
  padding: 20px 0;
  font-size: 14px;
  line-height: 22px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item:last-child {
  margin-bottom: 0;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item:nth-child(2n) {
  border-top: 1px solid #e1e5eb;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .avatar {
  width: 40px;
  height: 40px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-bottom: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 5px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title .child-item-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 4px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title .child-item-line .name {
  font-weight: 600;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title .child-item-line .name span {
  font-weight: 400;
  font-size: 12px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title .child-item-line .tag {
  float: right;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title .child-item-option {
  color: #747579;
  padding-left: 4px;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title .status {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.recordSummary[data-v-70722642] .el-tabs__content .recordSummary-list .recordSummary-item .el-card__body .recordSummary-item-main .child-item .child-item-block .child-item-title .status .el-link {
  cursor: auto !important;
}