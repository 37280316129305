.recordListTable .el-link[data-v-0536589c] {
  font-size: 12px;
}
.recordListTable .item[data-v-0536589c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.recordListTable .item span[data-v-0536589c] {
  width: 7px;
  height: 7px;
  margin-right: 6px;
  margin-bottom: 1px;
  border-radius: 50%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.recordListTable .signImg[data-v-0536589c] {
  width: 80px;
  cursor: pointer;
}