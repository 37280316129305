.comment-container[data-v-1f796568] {
  height: 100%;
  overflow: hidden;
}
.comment-container .comment-list[data-v-1f796568] {
  height: 100%;
  overflow: auto;
}
.comment-container .comment-list .item[data-v-1f796568] {
  padding: 0 30px;
  margin-bottom: 20px;
}
.comment-container .comment-list .item .head[data-v-1f796568] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.comment-container .comment-list .item .head .avatar[data-v-1f796568] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 20px;
}
.comment-container .comment-list .item .head .username[data-v-1f796568] {
  line-height: 40px;
  color: #333;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.comment-container .comment-list .item .head .head-right[data-v-1f796568] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.comment-container .comment-list .item .head .time[data-v-1f796568] {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 14px;
  color: #666;
  margin-left: 20px;
}
.comment-container .comment-list .item .content[data-v-1f796568] {
  font-size: 14px;
  padding-left: 60px;
}
.comment-container .comment-list .item .content .text[data-v-1f796568] {
  line-height: 30px;
  color: #666;
}
.comment-container .comment-list .item .content .img-list[data-v-1f796568] {
  margin-top: 6px;
}
.comment-container .comment-list .item .content .img-list .img-item[data-v-1f796568] {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: 0 6px 6px 0;
  border-radius: 6px;
}
.SNOWS-dialog .upload-btn[data-v-1f796568] {
  display: inline-block;
  margin-right: 20px;
}
.SNOWS-dialog .img-list[data-v-1f796568] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.SNOWS-dialog .img-list .img-item[data-v-1f796568] {
  width: 40px;
  height: 40px;
  position: relative;
  margin-right: 10px;
}
.SNOWS-dialog .img-list .img-item .el-image[data-v-1f796568] {
  width: 100%;
  height: 100%;
}
.SNOWS-dialog .img-list .img-item .badge[data-v-1f796568] {
  background-color: #f56c6c;
  border-radius: 10px;
  color: #fff;
  display: block;
  font-size: 12px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  border: 1px solid #fff;
  position: absolute;
  right: -9px;
  top: -9px;
  cursor: pointer;
  z-index: 10001;
}
.SNOWS-dialog .el-upload-list__item .el-upload-list__item-name[data-v-1f796568] {
  margin-right: 70px;
}
.SNOWS-dialog .el-upload-list__item .el-icon-download[data-v-1f796568] {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 25px;
  cursor: pointer;
  opacity: 0.75;
  color: #606266;
}
.SNOWS-dialog .el-upload-list__item .el-icon-view[data-v-1f796568] {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 45px;
  cursor: pointer;
  opacity: 0.75;
  color: #606266;
}